/** @jsx jsx */

import React from 'react';
import { graphql } from 'gatsby';
import { Styled, jsx, Box, Grid, Flex } from "theme-ui"
import styled from '@emotion/styled'

import Card from '../components/Card'
import BgImage from '../components/BgImage'

import Layout from "../components/layout"
import Button from '../components/button'

import SEO from "../components/seo"

import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";

import { Container } from '../components/item'
import IconGrid from '../components/IconGrid'

import ContactSection from '../components/ContactSection'


const StyledFlex = styled(Flex)`
  .image-gallery-thumbnails-wrapper {
    margin-top: 50px;
  }

  .image-gallery-thumbnail {
    margin: 5px;
  }

  .image-gallery-thumbnail.active {
    border: 4px solid black;
  }

  .image-gallery-thumbnail:hover {
    cursor: pointer;
    border: 4px solid #1C75BC;
  }

`

const StyledBox = styled(Box)`
  h1 {
    font-family: "Open Sans",sans-serif;
    font-weight: 800;
    font-size: 32px;
    text-transform: uppercase;
    margin-top: 8px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  p {
    font-size: 20px;
    line-height: 1.2;
    font-weight: normal;
  }
`

const StyledHeader = styled(Flex)`
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  padding: 5px;
`

const StyledCell = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  color: black;
  border: 1px solid black;
  text-align: center;
  padding: 10px;
`

const TableFlex = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;

  div:nth-of-type(n) {
    flex: 1 0 25%;
  }

  div:nth-of-type(-n+3) {
    flex: 1 0 33%;
  }

  @media screen and (max-width: 768px) {
    div:nth-of-type(n) {
      flex: 1 0 100%;
    }
  }

`

class ProductPageTemplate extends React.Component {

  render() {
    const {
      data: {
        // shopifyProduct: product,
        shopifyProduct: { title, descriptionHtml, images },
        shopifyunstableapi: {
          productByHandle: { col_1, col_2 , col_3, product_details }
        },
        allShopifyProduct: { edges },
        whiteAbstract: { childImageSharp },
      }
      // location: { pathname }
    } = this.props;

    const productDetails = JSON.parse(product_details.value);
  
    const imageList = Array.from(images, image => {
        return {
          original: image.localFile.childImageSharp.full.src, 
          thumbnail: image.localFile.childImageSharp.fixed.src
        }
    });

    const metadataArr = this.props.data.productsMetadata.products.edges;

    // const useCase = JSON.parse(use_case.value);

    return (
      <Layout reverseNav>
        <SEO title={title} />
        <Box as="section">
          <Container>
            <Flex pt={['80px','180px']} pb={13} sx={{width: '100%', color: 'black', flexDirection: ['column', 'row'] }}>
              <Flex sx={{flex: '1 50%', flexDirection: 'column', alignItems: 'center'}}>
                <StyledFlex sx={{maxWidth: '515px'}}>
                  <ImageGallery
                    items={imageList} 
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showNav={false}
                    lazyLoad={true}
                    />
                </StyledFlex>
              </Flex>
              <Box sx={{flex: '1 1 50%', marginTop: ['40px', 0]}}>
                <Styled.h2 as="h1">{title}</Styled.h2>
                <Styled.p sx={{variant: 'text.body'}} dangerouslySetInnerHTML={{
                  __html: descriptionHtml,
                }}></Styled.p>
                <Button to="/contact" text='Contact Us' />
              </Box>
            </Flex>
          </Container>
        </Box>

        {/* <Seperator /> */}
        
  
        <Box as="section">
          <Container py={8}>
            <StyledHeader sx={{backgroundColor: 'black', color: 'white'}}>
              <Styled.h2>Solar Energy</Styled.h2>
            </StyledHeader>
            <TableFlex>
              { productDetails.solarEnergy.map((detail, index) => (
                <StyledCell key={index}>
                  <Styled.p>{detail}</Styled.p>
                </StyledCell>
              ))}
            </TableFlex>
            <StyledHeader sx={{backgroundColor: 'primary', color: 'white', marginTop: 4}}>
              <Styled.h2>Visibile Light</Styled.h2>
            </StyledHeader>
            <TableFlex>
              { productDetails.visibleLight.map((detail, index) => (
                <StyledCell key={index}>
                  <Styled.p>{detail}</Styled.p>
                </StyledCell>
              ))}
            </TableFlex>
          </Container> 
        </Box>
  
        <Box as='section' color='white' bg='gold'>
          <Container py={13}>
            <Grid columns={[1, 3]} gap={[8, 8, 12]}>

              { col_1 != null &&
                <StyledBox>
                  <div dangerouslySetInnerHTML={{__html: col_1.value}} />
                </StyledBox>
              }
              { col_2 != null &&
                <StyledBox>
                  <div dangerouslySetInnerHTML={{__html: col_2.value}} />
                </StyledBox>
              }
              { col_3 != null &&
                <StyledBox>
                  <div dangerouslySetInnerHTML={{__html: col_3.value}} />
                </StyledBox>
              }
            </Grid>
          </Container>
        </Box>

        <IconGrid />

        <BgImage
          title=''
          fluid={childImageSharp.fluid}
        >
          <Container py={13} color='black'>
            <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: ['center', 'stretch']}}>
              {edges.map((edge) => {

                const productHandle = edge.node.handle;
                const metadata = (metadataArr.find(x => x.node.handle === productHandle).node.metafield);

                const blurbText = metadata ? metadata.value : 'Protection and privacy without glare, headlight distortion, or reduced visibility.';

                return (
                  <Card
                    key={edge.node.id}
                    color='black'
                    title={edge.node.title}
                    titleColor='black'
                    fluidImg={edge.node.images[0].localFile.childImageSharp.fluid}
                    roundEdges
                    shadow
                    text={blurbText}
                  >
                    <Button to={`/products/${edge.node.handle}`} text="See Product" />
                  </Card>
                ) 
              })}
            </Flex>
          </Container>
        </BgImage>

        <ContactSection title={'Contact Us'} alternate />
  
      </Layout>
    );
    
  }
}

export default ProductPageTemplate;

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      descriptionHtml
      images {
        id
        localFile {
          childImageSharp {
            full: fixed(width: 515) {
              ...GatsbyImageSharpFixed_withWebp
            }
            fixed: fixed(width: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    shopifyunstableapi {
      productByHandle(handle: $handle ) {
        col_1: metafield(key: "col_1",namespace: "product") {
          value
        }
        col_2: metafield(key: "col_2",namespace: "product") {
          value
        }
        col_3: metafield(key: "col_3",namespace: "product") {
          value
        }
        icons: metafield(key: "icons",namespace: "product") {
          value
        }
        product_details: metafield(key: "product_details",namespace: "product") {
          value
        }
      }
    }
    allShopifyProduct(filter: {vendor: {eq: "Raptect"}, handle: {ne: $handle}}, limit: 3, sort: {fields: handle}) {
      edges {
        node {
          id
          title
          handle
          images {
            localFile {
              childImageSharp {
                fluid(maxWidth: 520) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    productsMetadata: shopifyunstableapi {
      products(first: 10, query: "Raptect") {
        edges {
          node {
            handle
            metafield(key: "blurb", namespace: "product") {
              value
            }
          }
        }
      }
    }
    whiteAbstract: file(relativePath: { eq: "white-blue-abstract-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
